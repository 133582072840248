// List
#featured-posts {
    margin-bottom: 1rem;
    &.categories {
        h1 {
            margin-bottom: 2.5rem;
        }
    }
    h1 {
        // Copying style from h2
        font-weight: 500;
        margin-top: 0;
        font-size: 2.2rem;
        line-height: 2.4rem;
        letter-spacing: -0.09rem;
        @include breakpoint(sm) {
            font-size: 3rem;
            line-height: 3.3rem;
        }
        @include breakpoint(sm) {
            font-size: 3.5rem;
            line-height: 3.875rem;
        }
    }
    .post {
        margin-bottom: 2rem;
        @include breakpoint(md) {
            margin-bottom: 5rem;
        }
        .img-link {
            display: block;
        }
        img {
            max-width: 100%;
            height: auto;
            margin-bottom: 1.5rem;
            display: block;
        }
        h4 {
            //height: 72px;
            font-weight: bold;
            margin-top: 0.75rem;
            margin-bottom: 0.75rem;
            a {
                font-size: inherit;
                line-height: inherit;
                font-weight: inherit;
            }
        }
        p {
            margin-top: 0;
            margin-bottom: 1.5rem;
        }
        .small {
            display: block;
            overflow: hidden;
        }
        .author {
            margin-bottom: auto;
            font-size: 0.6875rem;
            line-height: 1rem;
            color: $dark_sub;
            span {
                font-weight: bold;
            }
        }
        .category {
            display: inline-block;
            border-radius: 10px;
            padding: 0.125rem 0.375rem;
            font-size: 0.6875rem;
            line-height: 1rem;
            background-color: #e3e3e3;
        }
        &.influencer-marketing,&.podcasts {
            .category {
                background-color: $green_sub;
            }
        }
        &.ambassador-marketing,
        &.micro-influencer-marketing,
        &.guides,
        &.cpg,
        &.tiktoc {
            .category {
                background-color: $red_sub;
            }
        }
        &.ugc,
        &.wikis {
            .category {
                background-color: $yellow_sub;
            }
        }
        &.podcasts {
            .category {
                background-color: $green_sub;
                // color: white;
            }
        }
    }
}

.posts-list-title p {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #cdcdcd;
    padding-bottom: 1rem;
}

.post-list {
    margin-bottom: 2.5rem;
    @include breakpoint(md) {
        margin-bottom: 5rem;
    }
    .col-sm-6 {
        margin-bottom: 1rem;
    }
    .post {
        padding: 1rem;
        position: relative;
        height: 100%;
        box-sizing: border-box;
        .title {
            margin-top: 0;
            margin-bottom: 3rem;
            font-weight: bold;
            a {
                font-size: inherit;
                line-height: inherit;
                font-weight: inherit;
            }
        }
        .author {
            margin: 0;
            font-size: 0.6875rem;
            line-height: 1rem;
            color: $dark_sub;
            position: absolute;
            bottom: 1rem;
            left: 1rem;
            span {
                font-weight: bold;
            }
        }
    }
    &.influencer-marketing, &.podcasts {
        .post {
            background-color: $green_sub;
        }
    }
    &.ambassador-marketing,
    &.micro-influencer-marketing,
    &.guides,
    &.cpg,
    &.tiktoc {
        .post {
            background-color: $red_sub;
        }
    }
    &.ugc,
    &.wikis {
        .post {
            background-color: $yellow_sub;
        }
    }
}
