#tooltip-element {
  &.is-relative{
    .tooltip{
      top: calc(100% + 0.5rem);
    }
  }
	a {
		cursor: default;
	}
	.tooltip {
		position: absolute;
		opacity: 0;
		transform: translateY(-5px);
		animation: display 0.15s ease forwards;
		right: -1rem;
		background: $dark;
		color: white;
		padding: 1rem;
		display: none;
		font-size: 0.7em;
		line-height: 1.2;
		font-weight: 400;
		z-index: 1;
		&.left {
			&:before {
				right: 0;
				left: 1rem;
			}
		}
		&:before {
			content: '';
			width: 5px;
			height: 5px;
			position: absolute;
			top: -5px;
			right: 1rem;
			border-style: solid;
			border-width: 0 5px 5px 5px;
			border-color: transparent transparent black transparent;
			box-sizing: border-box;
		}
	}
	&:hover {
		.tooltip {
			display: block;
		}
	}
	@keyframes display {
		from {
			opacity: 0;
			transform: translateY(-5px);
		}
		to {
			opacity: 1;
			transform: translateY(0px);
		}
	}
}
