#teaser-trial {
    margin-top: $mobilenavheight;
    padding-top: 4rem;
    padding-bottom: 4rem;
    background: $light_grey;
    @include breakpoint(sm) {
        margin-top: $navheight;
    }
    @include breakpoint(md) {
        padding-top: 6.75rem;
        padding-bottom: 6.75rem;
    }
    p.description {
        margin-bottom: 4rem;
    }
    p.features {
        margin-left: 30px;
        position: relative;
        svg {
            position: absolute;
            left: -32px;
            top: 0.5rem;
        }
        span {
            font-weight:bold;
        }
    }
    .btn {
        margin-bottom: 3rem;
        display: block;
        @include breakpoint(md) {
            margin-bottom: 0;
            display: inline;
        }
        span {
            font-weight: bold;
        }
    }
}

$plan-padding: 1.5rem;
#pricing_toggle{
    padding-top: 84px;
    margin-top: 94px;
    margin-bottom: 94px;
    @include breakpoint(sm-down) {
        padding-top: 4rem;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    #toggle-sec{
        @include breakpoint(sm-down) {
            gap: 1rem;
        }
        .toggle-container{
            padding: 31px 21px 36px 38px;
            background: #FFFFFF;
            border-radius: 32px;
            margin-bottom: 20px;
            opacity: 0.6;
            cursor: pointer;
            border: 1px solid #BDBDBD;
            transition: box-shadow .10s cubic-bezier(0.5, 0.1, 0.6, 0.5);
            @include breakpoint(sm-down) {
                padding: 20px 30px;
                border-radius: 12px;
            }
            &:hover{
             box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
             opacity: 1;
            }
            h3{
                margin: 0;
                margin-bottom: 0.8125rem;
                font-style: normal;
                font-weight: 500;
                font-size: 2.625rem;
                line-height: 3.25rem;
                letter-spacing: -0.4px;
                @include breakpoint(sm-down) {
                    font-size: 1.6rem;
                    line-height: 2rem;
                    margin-bottom: 0.4rem
                }
            }
            p{
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.625rem;
                @include breakpoint(sm-down) {
                  font-size: 0.9rem;
                  line-height: 1.2rem;
                  font-weight: 300;
                }
            }
        }
        .--active{
            border: 2px solid #141514;
            opacity: 1;
            pointer-events: none;
        }
    }
}
#pricing {
    display: none;
    margin-top: 40px;
    // padding-top: 3.8rem;
    @include breakpoint(sm) {
        margin-top: 70px;
        // padding-top: 60px;
    }
    @include breakpoint(md) {
        margin-top: 160px;
        // padding-top: 96px;
    }
    .pricing-header {
        text-align: left;
        margin-bottom: 20px;
        text-align: center;
        // margin-top: 30px;
        @include breakpoint(md) {
            margin-top: 0;
            margin-bottom: 100px;
        }
    }
    .pricing-schedule {
        @include breakpoint(sm-down) {
            text-align: center;
        }
        & > span {
            display: inline-block;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
            vertical-align: middle;
            &:first-child {
                margin-right: 2rem;
                &.disabled{
                    opacity: .2;
                }
            }
            span {
                vertical-align: middle;
                &.disabled{
                    opacity: .2;
                }
                &.deal {
                    margin-left: 5px;
                    font-size: 0.6875rem;
                    line-height: 1rem;
                    color: $dark;
                    animation: crazy 2s infinite;
                    border-radius: 10px;
                    padding: 0.1rem 0.3rem;
                }
            }
        }
    }
    #pricing-row {
        &.monthly {
            #monthly-schedule {
                font-weight: bold;
                text-decoration: underline;
            }
            .plan .monthly-price {
                display: inline-block;
            }
        }
        &.yearly {
            #yearly-schedule span:not(.deal) {
                font-weight: bold;
                text-decoration: underline;
            }
            .plan .yearly-price {
                display: inline-block;
            }
        }
        .currency {
            font-size: 0.6em;
        }
    }
    .col-md-4 {
        margin-bottom: 1rem;
        @include breakpoint(lg) {
            margin-bottom: 0;
        }
    }
    .plan {
        padding: $plan-padding;
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &#growth {
            background: $red_sub;
            a.btn {
                background: $red;
                background-color: $red;
                border-color: $red;
            }
        }
        &#brand {
            background: $yellow_sub;
            a.btn {
                background: $yellow;
                background-color: $yellow;
                border-color: $yellow;
            }
        }
        &#agency {
            background: $green_sub;
            a.btn {
                background: $green;
                background-color: $green;
                border-color: $green;
            }
        }
        &#business {
            margin-top: 0;
            @include breakpoint(md) {
                margin-top: 5.25rem;
                margin-bottom: 5.25rem;
            }
            background: #F8F8F8;
            .content{
                padding: 0;
                padding-bottom: 4.375rem;
                @include breakpoint(sm) {
                    padding: 4.375rem;
                    padding-bottom: 32px;
                }
            }
            a.btn {
                border: none !important;
                margin-top: 0;
                padding-bottom: 1.225rem;
                &.btn-outline {
                    border: 1px solid #000000 !important;
                    font-weight: 400 !important;
                    margin-top: 12px !important;
                    span {
                        margin-right: 8px;
                    }
                }
            }
        }
        h2 {
            font-weight: 400;
            margin-bottom: 0;
        }
        .cs-pricing__heading {
            font-size: 42px;
            line-height: 52px;
            font-weight: 500;
            margin-top: -16px;
        }
        .monthly-price, .yearly-price {
            font-weight: 500;
            display: none;
        }
        p.title {
            margin: 0;
        }
        p.description {
            margin: 0;
            padding-bottom: 2.25rem;
            color: $dark_sub;
            font-weight: 400;
        }
        a.btn {
            display: block;
            margin-top: 3rem;
            font-weight: bold;
        }
        div.features {
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            padding-top: 2.25rem;
            &.featured-business{
                border-top: 1px solid rgba(0, 0, 0, 0.2); 
                border-left: none;
                padding-top: 2.25rem;
                margin-right: 0;
                margin-left: 0;
                @include breakpoint(sm) {
                    padding-left: 4.375rem;
                    padding-right: 4.375rem;
                    padding-top: 0;
                    margin-top: 2.25rem;
                    margin-bottom: 2.25rem;
                    border-left: 1px solid rgba(0, 0, 0, 0.2);
                    border-top: none;
                    padding-top: 0;
                } 
            }
            [class*="col-"] {
                align-content: center;
                padding-left: 0;
                padding-right: 0;
                @include breakpoint(sm-down) {
                    max-width: none;
                    padding-left: .2rem;
                    padding-right: .2rem;
                }
            }
            p {
                &.disabled span:first-child{
                    opacity: 0.2;
                }
                font-size: 1rem;
                position: relative;
                margin-top: 30px;
                margin-bottom: 24px;
                svg.icon--check, svg.icon--close {
                    position: absolute;
                    left: -32px;
                    top: 0.5rem;
                }
                svg.icon--info {
                    position: absolute;
                    cursor: pointer;
                    right: 0;
                    top: 0.5rem;
                    opacity: 0.25;
                    transition: opacity 0.2s;
                    &:hover{
                        opacity: 1;
                    }
                }
                .small {
                    font-size: 0.6em;
                }
                &.not {
                    color: rgba(0, 0, 0, 0.25);
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &.empty {
                    display: none;
                    @include breakpoint(sm) {
                        display: block;
                    }
                }
            }
        }
    }
}
// how-it-works-affiliate is from the affiliate page, but same style
#included, #how-it-works-affiliate, #how-it-works-features {
    .aside-content{
       @include breakpoint(md-down) {
        margin-bottom: 3.125rem;
        br{
            display: none;
        }
       } 
    }
    .btn-section{
        margin-top: 3.125rem;
        .btn{
            font-weight: bold;
        }
    }
    h4 {
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 1rem;
    }
    p {
        max-width: 17.5rem;
        margin-top: 0.7rem;
        &.title {
            font-weight: bold;
            margin-bottom: 1rem;
        }
        &.small {
            margin-bottom: 3rem;
            &:last-child {
                margin-bottom: 0;
            }
            &.subtitle {
                color: $dark_sub;
                margin-bottom: 2rem;
                a {
                    color: $dark_sub;
                    text-decoration: underline;
                }
            }
        }
    }
}

.--show{
    display: block !important;
}