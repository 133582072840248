#linkedin-mockup-preview{
    font-family: $facebookfont;
    .preview-header{
        padding: 12px 12px 0px;
    }
    .linkedin-cta-button{
        color: #3072AC;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        padding:  3px 18px;
        background: transparent;
        border: 1px solid #3072AC;
        border-radius: 34px;
    }
}
#ib_linkedin-creadincials{
    gap: 7px;
    .profile-picture-wrapper{
        border-radius: initial;
        border: none;
        background: #DFDFDF
    }
    .user-name-wrapper{
        .user-name{
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.015em;
            color: #191919;
            margin-bottom: 3px;
        }
        .sponcered-text{
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.01em;
            color: #707070;
        }
    }
}
.linkedin-caption{
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.025em;
    color: #191919;
    // margin-top: 5px;
    padding: 8px 12px;
}
.linkedin__cta-wrapper{
    padding: 12px;
    background: #EEF3F8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    .linkedin__headline-website{
        .linkedin__cta-website{
            display: block;
            font-weight: 600;
            font-size: 13px;
            line-height: 10px;
            letter-spacing: 0.035em;
            color: #191919;
        }
        .linkedin__cta-headline{
            display: block;
            font-weight: 600;
            font-size: 12px;
            line-height: 10px;
            letter-spacing: 0.005em;
            color: rgba(0, 0, 0, 0.6);
            margin-top: 6px;
        }
    }

}
.linkedin__like-comment-section{
    padding: 12px 0px;
    margin: 0px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #DADDE1;
    .linkedin_react-sec-wrapper{
        display: flex;
        gap: 6px;
        align-items: center;
        justify-content: center;
        .linkedin__react-emoticon{
            display: flex;
            img:not(:first-child){
                margin-left: -4px;
            }
        }
        .react-sec{
            font-weight: 400;
            font-size: 13px;
            line-height: 17px;
            color: #707070;
        }
    }
    .comment_share-sec{
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        text-align: right;
        color: #707070;
    }
}
.linkedin__like-comment-cta{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 21px;
    align-items: center;
    .wrapper{
        display: flex;
        gap: 4px;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #707070;
        &:first-child{
            gap: 7px;
        }
    }
}
.ib_linkedin-slide{
    // display: flex;
    flex-direction: column;
    border: 1px solid #E5E5E5;
    border-radius: 11px;
    overflow: hidden;
    width: 300px !important;
    position: relative !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    &:first-child{
        margin-left: 12px;
    }
    &:last-child{
        margin-right: 12px;
    }
    // display: block;
    .post-wrapper{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: #F9F9F9;
        img{
            height: 100%;
            width: 100%;
        }
    }
    .linkedin__carousel-cta{
        width: 100%;
        border-top: 1px solid #E5E5E5;
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 10px 12px;
        box-sizing: border-box;
        background: #FFFFFF;
        .slide-text{
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: #000000;
            display: block;
            flex: 1;
        }
    }
}
.ib-linkedin-carousel{
    height: 100%;
    width: 100%;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    gap: 10px;
    // padding-bottom: 12px;
    scrollbar-width: none;
    position: relative;
    &::-webkit-scrollbar {
        display: none;
      }

}
.ib__linkedin-carousel-wrapper{
    background: transparent;
    position: relative;
    &:hover{
        .btn{
            opacity: 1;
        } 
    }
    .btn {
        background: #FFFFFF;
        border: 1px solid #CFD0D4;
        height: 49px;
        width: 49px;
        border-radius: 50%;
        position: absolute;
        top: 40%;
        color: #66676B;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
      .btn-next{
        right: 12px;
      }
      .btn-prev{
        left: 12px;
    }
}
.ib__mockup-input-wrapper{
    margin-top: 24px;
    input{
        margin-top: 12px;
    }
}