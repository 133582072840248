#testimonials {
	background-color: $light_grey;
	.box {
		@include breakpoint(md-down) {
			margin: 0.625rem;
		}
		display: flex;
		.inner-box {
			background-color: $white;
			padding: 45px 32px;
			p {
				margin: 0;
				padding: 0;
			}
			.client {
				padding-top: 32px;
				display: flex;
				img {
					float: left;
					align-self: center;
				}
				.client-info {
					padding-left: 25px;

					.job {
						opacity: 0.7;
						color: $dark_sub;
					}
				}
			}
		}
	}
}