// Colors
$dark: #141514;
$dark_sub: rgba(0, 0, 0, 0.5);
$secondary: #EE3465;
$neutral: #DFE4EA;
$placeholder: #EFF2F6;
$white: #FFFFFF;
$red: #E7AEC0;
$red_sub: #FFE9F0;
$yellow: #D0B594;
$yellow_sub: #FAECDB;
$green: #95BEB0;
$green_sub: #D6EBE4;
$light_grey: #F9F7F7;
$light_green: #EDF9EB;

//features colors
$light_green: #DBEBD6;
$light_green_accent: #E3EBD6;
$light_red: #FADFDB;
$light_blue: #D6EBE6;
$light_blue_accent: #D6EBDD;

//menu hover
$quicksearch: #F3F9F7;
$deepsearch: #FCF3FB;
$contactexports: #F1FAF0;
$creatorstudio: #F1F2F8;

//follower score
$low: #F6E0E3;
$low_text: #B5757D;

$medium: #FDEBE3;
$medium_text: #B47963;

$fair: #F8F0DC;
$fair_text: #B47963;

$great: #D7EBE4;
$great_text: #4F7D6D;


// Widths
$xl: 100em; // equivalent to 1600px with default font-size of 16px
$lg: 75em; // equivalent to 1200px. From Flexboxgrid.
$md: 64em; // equivalent to 1024px. From Flexboxgrid.
$sm: 48em; // equivalent to 768px. From Flexboxgrid.
$xs: 25em; // equivalent to 400px

// Fonts
$font: "neue-haas-unica", sans-serif;
$blogfont: "lora", serif;
$facebookfont: "Segoe UI", sans-serif;
$instagramfont: 'Inter', sans-serif;
$tiktokfont: "Proxima Nova", sans-serif;
$weight_bold: 600;

// Utils
$br: 4px;
$navheight: 5.25rem;
$mobilenavheight: 3.125rem;


// Transition
$transition_default: all .12s cubic-bezier(0.5, 0.1, 0.6, 0.5);

//Ad Mockup navigation colors
$instagram_bg: #F8E3D8;
$instagram_font_hover: #723F24;
$instagram_bg_focus: #DEBFAF;

$facebook_bg: #D8E7F0;
$facebook_font_hover: #185072;
$facebook_bg_focus: #A5C2D3;

$tiktok_bg: #FFE5EE;
$tiktok_font_hover: #831E42;
$tiktok_bg_focus: #DDA9BB;

$snapchat_bg: #F6F1C2;
$snapchat_font_hover: #4D4827;
$snapchat_bg_focus: #D3CA81;

$linkedin_bg: #D0D5DD;
$linkedin_font_hover: #1D3A69;
$linkedin_bg_focus: #8D9FBC;

$twitter_bg: #CCE8EA;
$twitter_font_hover: #166A70;
$twitter_bg_focus: #549599;
