.unlock-calculator-popup {
  padding: 0px 24px;

  .content {
    background-color: white;
    width: 100%;
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    @media screen and (max-width: 540px) {
      width: 400px;
    }

    @media screen and (max-width: 400px) {
      width: 350px;
    }

    .content-group {
      padding: 0px 80px;
      display: flex;
      flex-direction: column;
      gap: 56px;

      .header {
        color: #141514;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 0px;

        .title {
          font-size: 28px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: -0.3px;
          margin: 0;

          @media screen and (max-width: 540px) {
            font-size: 24px;
          }
        }

        .subtext {
          opacity: 0.5;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          margin: 0px;
        }
      }

      .cta {
        display: flex;
        gap: 16px;

        @media screen and (max-width: 540px) {
          flex-direction: column;
          gap: 12px;
        }

        button {
          height: 90px;
          width: 225px;
          transition: opacity 0.2s ease-in-out;
          border-radius: 2px;

          @media screen and (max-width: 540px) {
            width: 300px;
            margin: 0 auto;
          }

          &.btn-outline {
            &:hover {
              background-color: rgb(241, 241, 241);
              opacity: 1;
            }
          }

          &:hover {
            cursor: pointer;
            opacity: 0.9;
          }
        }
      }

    }
  }

  .spinner {
    display: inline-block;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.10s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}