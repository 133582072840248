#hero {
  padding-top: 12rem;
  padding-bottom: 8rem;
  text-align: left;

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 100px;
    
    
    @include breakpoint(md-down) {
      gap: 50px;
      h1 {
        font-size: 3rem;
        line-height: 1.2;
      }
      p {
        font-size: 1rem;
        line-height: 1.2;
      }
    }

    @include breakpoint(sm-down) {
      grid-template-columns: 1fr;
      gap: 0px;
      img {
        display: none;
      }
      text-align: center;
    }
  }

    .hero-image {
      display: flex;
      justify-content: flex-end;
      img {
        width: 90%;
      }
    }

  h1 {
    color: #141514;
    font-size: 4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 4rem;
    letter-spacing: -0.0625rem;
  }

  p {
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.625rem;
    opacity: 0.75;
    margin-bottom: 2rem;
  }

  .cta {
    a {
      display: block;
      margin: 0 auto 20px;
      width: 100%;

      @include breakpoint(31.25rem) {
        width: 16.75rem;
      }

      @include breakpoint(sm) {
        display: inline-block;
        margin: 0;

        &:first-child {
          margin-right: 20px;
        }
      }

      &#hero-cta {
        @include breakpoint(sm-down) {
          color: black;
          border: none;
          animation: crazy 2s infinite;
        }
      }

      span {
        font-weight: bold;
      }
    }

    a:nth-child(2) {
      padding-left: 20px;

      span {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .btn:nth-child(2) {
      margin-left: 15px;
      padding-left: 10px;
      span {
        padding-left: 20px;
        padding-right: 30px;
      }
    }

    .btn:nth-child(1) {
      border: none;
    }

  }
}

#clients {
  .row {
    margin: 0;
  }

  @include breakpoint(md-down) {
    max-width: 34rem;
  }

  .icons {
    @include breakpoint(md-down) {
      flex-basis: 33.33333%;

      &:nth-child(3n + 2) {
        text-align: center;
      }

      &:nth-child(3n) {
        text-align: right;
      }

      svg {
        margin-bottom: 1rem;
      }
    }

    @include breakpoint(sm-down) {
      svg {
        max-width: 70%;
        max-height: 2.5rem;
      }
    }
  }

  svg {
    fill: $dark;
  }
}

.home-features {
  padding: 30px 25px;
  box-sizing: border-box;
  outline: none !important;

  @include breakpoint(xs) {
    padding: 50px 35px;
  }

  @include breakpoint(sm) {
    padding: 58px 51px;
  }

  @include breakpoint(sm-down) {
    margin: 0 !important;
  }

  &#search {
    background-color: $yellow_sub;
  }

  &#pick {
    background-color: $red_sub;
  }

  &#export {
    background-color: $green_sub;
  }

  .left-col {
    position: relative;
  }

  p {
    @include breakpoint(md) {
      margin-bottom: 4rem;
    }

    span {
      font-weight: bold;
    }
  }

  a.learn-more {
    font-weight: bold;
    display: block;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $dark;
    margin-bottom: 2.5rem;

    @include breakpoint(md) {
      margin-bottom: 4rem;
    }
  }

  a.question {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $dark_sub;
    text-decoration: underline;

    @include breakpoint(md) {
      position: absolute;
      bottom: 0;
      left: 0.5rem;
    }
  }

  .icon-ctn {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 1rem;

    @include breakpoint(md) {
      margin-bottom: 0;
    }

    video {
      width: 100% !important;
      height: auto !important;
    }
  }
}

#extras {
  .icon-ctn {
    position: relative;
    width: 4.6875rem;
    height: 4.6875rem;
  }

  h3 {
    margin-top: 20px;
  }
}

#what-we-do {
  .new-badge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0px 12px 2px;
    width: 70px;
    height: 30px;
    background: linear-gradient(180deg, #ffe9f0 0%, #faecdb 51.04%, #d6ebe4 100%);
    border-radius: 360px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #141514;
    margin-bottom: 1rem;
  }

  background: $light_grey;
  padding: 50px auto;

  @include breakpoint(xs) {
    padding: 50px 35px;
  }

  @include breakpoint(sm) {
    padding: 58px 51px;
  }

  @include breakpoint(sm-down) {
    margin: 0 !important;
  }

  video {
    width: 100%;
    margin: 25px 0;
    border-radius: 10px;

    @include breakpoint(md) {
      width: 85%;
    }
  }

  .row {
    display: flex;
    align-items: center;

    @include breakpoint(md-down) {
      flex-direction: column-reverse;
    }

    p {
      opacity: 0.75;
    }

    .cta {
      a {
        border: none;
      }

      margin: 50px 0;
    }
  }
}

// cta-affiliate is from affiliate page, but same styling
#start-now,
#cta-affiliate {
  .col1 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  h4 {
    margin-bottom: 3rem;

    @include breakpoint(md) {
      margin-bottom: 3.5rem;
    }
  }

  p.signin {
    margin-top: 2.5rem;
    font-size: 0.6875rem;

    a {
      font-size: inherit;
      text-decoration: underline;
    }
  }

  .icon-ctn {
    background: $yellow_sub;
    width: 100%;
    height: 100%;
  }

  .btn span {
    font-weight: bold;
  }
}

#cta-home {
  border-top: 1px solid black;

  &.no-border {
    border-top: none !important;
  }

  .cta-button {
    margin-top: 3.75rem;
  }
}

.freetoolkit-sec {
  padding: 3rem 1.25rem;

  @include breakpoint(md) {
    padding-top: 7.93rem;
    padding-bottom: 8rem;
  }

  .toolkit-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h3 {
      font-weight: 500;
      font-size: 42px;
      line-height: 52px;
      letter-spacing: -0.4px;
      margin: 0;

      @include breakpoint(sm-down) {
        font-size: 30px;
        line-height: 36px;
      }
    }

    svg {
      display: block;
    }
  }

  .toolkits {
    .toolkit-title {
      margin-bottom: 48px;
    }
  }
}
.freetoolkit-sec {
  padding-bottom: 0px !important;
}
#influencer-database-tools {
  // background-color: $light_grey;
  .inner {
    background-color: white;

    // border: 1px solid transparent;
    &:hover {
      border: 1px solid #000000;
    }
  }
}
