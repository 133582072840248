#testimonials-industries.p-tb-80{
	padding-top: 64px;
	padding-bottom: 71px;
	@include breakpoint(md) {
		padding-top: 128px;
		padding-bottom: 96px;
	}	
}
#testimonials-industries {
	background-color: $light_grey;
	.cta{
		margin-top: 48px;
		margin-bottom: 64px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		@include breakpoint(md) {
			margin-bottom: 128px;
		}
		a{
			@include breakpoint(sm-down) {
				width: 100%;
			}
		}
	}
	.m-tb-40{
		@include breakpoint(sm-down) {
			margin-top: 14px;
			margin-bottom: 64px;
		}
		@include breakpoint(md) {
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}
	h3{
		margin-bottom: 0;
		@include breakpoint(sm-down) {
			font-size: 1.625rem;
			line-height: 2rem;
		}
	}

	.navigator{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		gap: 12px;
		margin-bottom: 22px;
		padding-top: 6px;

		.testimonial_circle{
			width: 8px;
			height: 8px;
			border-radius: 50%;
			border: 1px solid #C4C4C4;
		}
		.active{
			border:none;
			padding: 1px;
			background-color: black;
		}
	}
	.clients_card-container{
		margin-left: -2rem;
		margin-right: -1.5rem;
		display: flex;
		overflow-x: scroll;
		@include breakpoint(md) {
			margin: 0;
			overflow-x: hidden !important;
		}
	}
	.clients_card-container > *:first-child {
		padding-left: 2rem;
		@include breakpoint(md) {
			padding-left: 0;
		}
	  }
	.clients_card-container > *:last-child {
		padding-right: 1.5rem;
		@include breakpoint(md) {
			padding-right: 0;
		}
	  }
	.box {
		@include breakpoint(md-down) {
			margin: 0.625rem;
		}
		display: flex;
		.inner-box {
			width: calc(100vw - 160px);
			background-color: $white;
			padding: 45px 32px;
			@include breakpoint(sm) {
				width: 350px;
			}
			p {
				margin: 0;
				padding: 0;
			}
			.client {
				padding-top: 32px;
				display: flex;
				img {
					float: left;
					align-self: center;
				}
				.client-info {
					padding-left: 25px;

					.job {
						opacity: 0.7;
						color: $dark_sub;
					}
				}
			}
	
		}
	}
	.icon-group{
		display: flex; 
		overflow:hidden; 
		gap: 34px;
		align-items: center;
		margin: 0;
		padding: 0px 2rem;
		@include breakpoint(md) {
			// margin: 80px -3rem 0px;
			padding: 0px 0rem;
			justify-content: space-around;
			gap: 0;
			width: 76.8rem;
		}
	}
	  #clients-influencer{
		//   margin-left: -2rem;
		@include breakpoint(md) {
			width: 76.8rem;
			margin: 0px auto;
		}
		width: 100vw;
		margin: 0px -2rem;
		overflow:hidden;
		//   .marquee{
		//   position:relative;
		//   width:800px;
		//   @include breakpoint(md) {
		// 	width: 100%;
		// 	animation: none;
		// }
		//   animation:scroll 6s linear infinite;
		//   }
		.slider-static {
			@include breakpoint(md-down) {
				display: none;
			}
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.slider{
			@include breakpoint(md) {
				margin-bottom: 1rem;
				display: none;
			}
			.slide {
				display: flex;
				justify-content: center;
				padding: 0px 42px;
			}
			.slick-track{
				display: flex;
				align-items: center;
			}	
		}
		.marquee {
			
			// width: 800px;
			position: relative;
			overflow: hidden;
			@include breakpoint(md) {
			width: 100%;
			animation: none;
		    }
			// animation: marquee 20s linear infinite;
			// span {
				display: flex;
				float: left;
				width: 100%;
				justify-content: space-between;
				align-items: center;
			//   }

			.marquee--inner {
				display: flex;
				width: 200%;
				position: absolute;
				// animation: marquee 20s linear infinite;

			  }
		  }
		  .marquee:hover {
			animation-play-state: paused
		}
		@keyframes marquee {
			0% { left: 0; }
			100% { left: -100%; }
		  }
	  }
}
