#engagement-header {
    text-align: center;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12.5rem;
    h1 {
        @include breakpoint(sm) {
            font-size: 4rem;
            line-height: 4.6rem;
        }
    }
    p br {
        @include breakpoint(sm-down) {
            display: none;
        }
    }

    .chrome-tag{
      width: 100%;
      text-align: center;
      margin-top: 3.125rem;
      margin-bottom: 8rem;
      transition: $transition_default;
      cursor: pointer;
      &:hover{
        filter: drop-shadow(0px 2.51281px 6.91022px rgba(0, 0, 0, 0.1));
      }
      &:active{
        filter: drop-shadow(0px 1.25641px 3.45511px rgba(0, 0, 0, 0.1));

      }
    }
    .chrome-exentsion-tag{
      margin-bottom: 1.25rem;
      border-radius: 360px;
      background-color: #EEEEEE;
      color: black;
      padding: 3px 14px;
      max-width: 123px;
      margin-left: auto;
      margin-right: auto;
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
    }
}

#engagement-form {
    max-width: 700px;
    margin: 50px auto 60px;
    text-align: center;

    input {
        background: transparent;
        width: 90%;
        max-width: 350px;
        border: 1px solid #000000;
        outline: none;
        height: 70px;
        padding: 5px 25px;
        box-sizing: border-box;
        margin-bottom: 10px;
        @media screen and (min-width: 700px) {
            margin-bottom: 0px;
            margin-right: 10px;
        }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(0, 0, 0, 0.25);
    }
    }
    button {
        background-color: $dark;
        color: white;
        height: 70px;
        border: none;
        padding: 10px 0px;
        width: 224px;
        cursor: pointer;
        border-radius: 0;
        outline: none !important;
    }
}

#engagement-calculator {
    margin-bottom: 80px;
    .engagement-loader{
        .loader-square--tiny{
            margin-top: 35px;
        }
    }
    .card {
        border: 1px solid #dcdcdc;
        box-sizing: border-box;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
        border-radius: 1px;
        padding: 20px 15px;
        @include breakpoint(sm) {
            padding: 20px 25px;
        }
    }
    .error {
        text-align: center;
        color: red;
        margin-top: -20px;
        margin-bottom: 60px;
    }
    .maintenance {
        text-align: center;
        color: red;
        margin-top: 40px;
        margin-bottom: 60px;
    }
}
#engagement-result {
    margin-bottom: 60px;
    & > div {
        @include breakpoint(sm) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
    .profile-info {
        margin-top: 40px;
        text-align: center;
        img {
            width: 125px;
            height: 125px;
            border-radius: 50%;
            cursor: pointer;
        }
        .username {
            font-weight: 700;
            margin-bottom: 0;
            margin-top: 10px;
            cursor: pointer;
        }
        span {
            font-size: 14px;
            line-height: 1;
            color: $dark_sub;
        }
        .stats {
            border-top: 1px solid rgba(0, 0, 0, 0.25);
            border-bottom: 1px solid rgba(0, 0, 0, 0.25);
            padding: 25px;
            margin: 60px 0 45px;
            p {
                margin: 0;
                font-weight: 500;
                line-height: 1;
            }
        }
        a {
            width: 100%;
            font-weight: 700;
            display: block;
            margin-bottom: 40px;
            @include breakpoint(md) {
                margin-bottom: 0;
            }
        }
    }
    .posts-info {
        text-align: center;
        & > .row > div {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
        .stats {
            p {
                font-weight: 700;
                margin-bottom: 0;
                margin-top: 10px;
                line-height: 1;
            }
            span {
                font-size: 14px;
                line-height: 16px;
                color: $dark_sub;
                display: block;
                margin-top: 5px;
            }
            .card {
                height: 100%;
            }
            &.likes svg {
                fill: $red;
            }
            &.engagement svg {
                fill: $green;
            }
            &.comments svg {
                fill: $yellow;
            }
        }
        .graph {
            text-align: left;
            p {
                font-weight: 700;
                margin-top: 0;
            }
            .curve-inbeat {
                .stroke {
                    stroke: $red;
                    stroke-width: 2;
                }
                .fill {
                    fill: url(#graphFill);
                    fill-opacity: 0.5;
                }
            }
            .label {
                font-size: 8px;
            }
        }
        .posts {
            text-align: left;
            .card-title {
                font-weight: 700;
                margin-top: 0;
            }
            .post {
                border: 1px solid #ebebeb;
                border-radius: 2px;
                margin-bottom: 20px;
                text-align: center;
                cursor: pointer;
                .img {
                    padding-top: 100%;
                    background-size: cover;
                    position: relative;
                    background-position: center;
                    .views {
                        position: absolute;
                        bottom: 2px;
                        left: 10px;
                        svg {
                            width: 10px;
                            height: 10px;
                            vertical-align: middle;
                            margin-right: 3px;
                        }
                        color: white;
                        font-size: 12px;
                    }
                }
                p {
                    font-size: 12px;
                    line-height: 18px;
                    margin: 7px 0;
                    svg {
                        width: 8px;
                        height: 8px;
                        vertical-align: middle;
                        margin: 0 2px 0;
                        fill: #d6d6d6 !important;
                    }
                    span {
                        display: inline-block;
                        &:first-child {
                            svg  {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 19px;
        height: 19px;
        margin: 2px;
        border: 2px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
        &:nth-child(1) {
            animation-delay: -0.45s;
        }
        &:nth-child(2) {
            animation-delay: -0.3s;
        }
        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.slide-fade-enter-active {
    transition: all 0.2s ease;
}
.slide-fade-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}

.calc-cta-btn {
    letter-spacing: 0 !important;
}

