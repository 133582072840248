.noTrigger {
	pointer-events: none !important;
}
.popupTrigger {
	cursor: pointer;
}
.popupOverlay {
	z-index: 500;
	opacity: 0;
  visibility: hidden;
	position: fixed;
  display: flex;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	transition: $transition_default;
	justify-content: center;
	align-items: center;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  padding: 12px;

  &.show {
    opacity: 1;
    visibility: visible;
  }
	.popup {
		height: auto;
		transform: translateY(0px);
		transition: $transition_default;

		&.auto-wh {
			width: auto;
			height: auto;
      background-color: white;
		}
		.content-wrapper {
			video {
				width: 100%;
			}
			.content-video {
				padding: 0;
				height: 400px;
				width: 711px;
				@include breakpoint(sm-down) {
					height: 260px;
					width: 462.22px;
				}
				@include breakpoint(sm) {
					height: 400px;
					width: 711px;
				}
			}
		}

		.close {
			z-index: 100;
			cursor: pointer;
			position: absolute;
			top: -40px;
			right: 0;
			svg {
				width: 20px;
				height: 20px;
				path {
					stroke: white;
				}
			}
		}
		.btn {
			width: 100% !important;
			background-color: #000000;
			color: white;
			left: 0;
			height: 80px;
			border: none;
			font-size: 18px;
			font-weight: 400;
			bottom: 0;
			padding: 5px 0px;
			cursor: pointer;
			border-radius: 0;
			outline: none !important;
			@include breakpoint(sm) {
			}
		}
	}
}
