.related-tools {
    margin-top: 128px;
    @include breakpoint(sm-down) {
        margin-top: 64px;
        margin-left: 1.25rem;
        margin-right: 1.25rem;
    }
.related_tools-header{
    padding-bottom: 48px;
    border-bottom: 1px solid #000000;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    @include breakpoint(sm-down) {
        padding-bottom: 25px;
        font-size: 2.8rem;
    }
    a.view-all {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        text-align: right;    
        color: black;
        margin-bottom: 0;
        letter-spacing: -0.3px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        opacity: 0.5;
        .right-arrow-icon{
            padding-left: 8px;
            padding-bottom: 5px;
            // opacity: 0.5;
            transition: all 0.3s ease-in-out;
        }
        @include breakpoint(sm-down) {
            display: none;
            margin-bottom: 16px;
        }
        &:hover{
            opacity: 1;
        }
    }
}
    .related-tools-title{
        margin: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 3.5rem;
        line-height: 4rem;
        letter-spacing: -1.4px;
        color: #141514;
        @include breakpoint(sm-down) {
            font-size: 30px;
            line-height:36px;
           }
    }
    .tools-card-wrapper{
        display: flex;
        flex-wrap: wrap;
        margin-top: 48px;
        @include breakpoint(sm-down) {
        overflow-x: auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        margin: -0.6rem;
        padding: 0.75rem;
        margin-top: 24px;
        row-gap: 8px;
        }
    }
    .tools {
        @include breakpoint(sm-down) {
            padding-right: 0.25rem;
            padding-left: 0.25rem;
            }
        width: 100%;
        &:hover .inner{
            border: 1px solid rgba(0, 0, 0, 1);
            cursor: pointer;
        }
        .inner {
            transition: all .05s cubic-bezier(0.55, 0.085, 0.68, 0.53);
            display: flex;
            border: 1px solid rgba(0, 0, 0, 0.20);
            // height: 100%;
            padding: 14px;
            flex-direction: row-reverse;
            height: 100%;
            box-sizing: border-box;
            @include breakpoint(sm-down) {
                flex-direction: row;
                padding: 10px 25px;
                width: 65vw;
                min-height: 188px;
                .icon{
                    @include breakpoint(sm) {
                        position: absolute;
                    }
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 25px;
                }
            }
            .icon{
                @include breakpoint(sm) {
                    position: absolute;
                }
            }
            .content{
                padding: 36px 16px 16px 16px;
                @include breakpoint(sm-down) {
                    padding: 25px 0px 25px 0px;
                }
                .tool-description{
                    display: inline-block;
                    align-self: flex-end;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: -0.3px;
                    color: #141514;
                    margin: 0;
                    @include breakpoint(sm-down) {
                          // display: none;
                           font-size: .75rem;
                           line-height: 1.25rem;
                    }
                }
                .tool-name{
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 31px;
                    letter-spacing: -0.3px;      
                    color: #000000;
                    margin: 0;
                    margin-bottom: 22px;
                    // width: 46.6%;
                    @include breakpoint(sm-down) {
                        font-size: 1.2rem;
                        margin-bottom: 15px;
                        width: 100%;
                    }
                }
            }
        }
    }
}